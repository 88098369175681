<template>
    <div class="DailySettle" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                :model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-select v-model="form.deptGroupCode" filterable :loading="loadingDeptGroupFlag">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="group in meta.groups"
                                    :value="group.code"
                                    :key="group.code"
                                    :label="group.name"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="商品类型">
                            <el-select v-model="form.goodsType">
                                <el-option :value="1" label="折扣券" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-date-picker v-model="form.createDate" type="date" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-card shadow="never" style="margin-top: 8px; text-align: left">
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                    <el-button type="primary" @click="handleAdd" size="small">添加</el-button>
                </el-card>
                <el-card shadow="never" style="margin-top: 8px">
                    <el-table
                        id="printMe"
                        border
                        stripe
                        style="width: 100%"
                        :data="tableData"
                        size="mini"
                        :highlight-current-row="true"
                        max-height="520"
                    >
                        <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                        <el-table-column label="序号" width="60" type="index" fixed="left" />
                        <el-table-column label="商品名称" width="240" prop="code">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.names" size="mini" />
                            </template>
                        </el-table-column>
                        <el-table-column label="商品描述" width="240" prop="deptGroupName">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.descriptions" size="mini" />
                            </template>
                        </el-table-column>
                        <el-table-column label="折扣" width="120" prop="operatorName">
                            <template slot-scope="scope">
                                <ef-price-input
                                    v-model="scope.row.discounts"
                                    :min="0.1"
                                    :max="10"
                                    :precision="1"
                                    size="mini"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column label="可用次数" width="120" prop="reviewStatus">
                            <template slot-scope="scope">
                                <ef-price-input
                                    v-model="scope.row.canUseTimes"
                                    :min="1"
                                    :max="9999"
                                    :precision="0"
                                    size="mini"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column label="有效期" width="200">
                            <template slot-scope="scope">
                                <el-date-picker
                                    v-model="scope.row.effectiveTimes"
                                    style="width: 150px"
                                    value-format="yyyy-MM-dd"
                                    size="mini"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column label="购买积分" width="160">
                            <template slot-scope="scope">
                                <ef-stock-biz-count-input v-model="scope.row.buyPoints" />
                            </template>
                        </el-table-column>
                        <el-table-column label="库存数量" width="160">
                            <template slot-scope="scope">
                                <ef-stock-biz-count-input v-model="scope.row.stocks" />
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" width="145" header-align="center">
                            <template slot-scope="scope">
                                <el-button
                                    size="mini"
                                    type="danger"
                                    @click="deleteRow(scope.$index, tableData, scope.row)"
                                    >删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        :page-sizes="page.pageSizes"
                        :page-size="form.limit"
                        :layout="page.PageStyle"
                        :total="page.total"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        @prev-click="handlePrevClick"
                        @next-click="handleNextClick"
                    />
                </el-card>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import EfPriceInput from 'components/EfPriceInput';
import EfStockBizCountInput from 'components/EfStockBizCountInput';

export default {
    name: 'PointGoodsCreate',
    components: { EfPriceInput, EfStockBizCountInput },
    data() {
        return {
            form: {
                deptGroupCode: '',
                goodsType: 1,
                createDate: new Date(),
                creator: this.$store.state.session.name,
                page: 1,
                limit: 100,
            },
            meta: {
                groups: [],
            },
            loadingDeptGroupFlag: true,
            url: {
                save: '/member/pointGoods/createExtend',
                queryGroups: '/system/deptGroup/list',
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            codes: [],
            tableData: [],
        };
    },
    mounted() {
        UrlUtils.DeptGroup(this, (rst) => {
            this.meta.groups = rst || [];
            this.loadingDeptGroupFlag = false;
        });
    },
    methods: {
        handleSave() {
            const _this = this;
            if (Util.isEmpty(this.form.deptGroupCode)) {
                this.$message.error('请选取机构组');
                return;
            }
            for (const value of this.tableData) {
                if (Util.isEmpty(value.stocks)) {
                    this.$message.error('请填写库存数量');
                    return false;
                }
                if (Util.isEmpty(value.buyPoints)) {
                    this.$message.error('请填写购买积分');
                    return false;
                }
                if (Util.isEmpty(value.names)) {
                    this.$message.error('请填写商品名称');
                    return false;
                }
                if (Util.isEmpty(value.descriptions)) {
                    this.$message.error('请填写商品描述');
                    return false;
                }
                if (Util.isEmpty(value.effectiveTimes)) {
                    this.$message.error('请填写有效期');
                    return false;
                }
                if (Util.isEmpty(value.discounts)) {
                    this.$message.error('请填写可用次数');
                    return false;
                }
                if (Util.isEmpty(value.canUseTimes)) {
                    this.$message.error('请填写折扣');
                    return false;
                }
            }
            const _params = {
                names: _this.tableData.map((d) => d.names),
                descriptions: _this.tableData.map((d) => d.descriptions),
                discounts: _this.tableData.map((d) => d.discounts),
                canUseTimes: _this.tableData.map((d) => d.canUseTimes),
                effectiveTimes: _this.tableData.map((d) => d.effectiveTimes),
                buyPoints: _this.tableData.map((d) => d.buyPoints),
                stocks: _this.tableData.map((d) => d.stocks),
                deptGroupCode: _this.form.deptGroupCode,
                goodsType: _this.form.goodsType,
            };
            UrlUtils.PostRemote(this, this.url.save, _params, null, () => {
                _this.goBackAndReload();
            });
        },
        deleteRow(index, rows, row) {
            rows.splice(index, 1);
        },
        handleAdd() {
            const _this = this;
            _this.tableData.push({
                sex: 0,
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
